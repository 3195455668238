import {
  createResource,
  getResource,
  postResource,
} from '@/js/resource/resource'

import { DOCUMENT } from './paths'

export const documentListResource = createResource(
  DOCUMENT.LIST, getResource
)

export const groupContractCreateResource = createResource(
  DOCUMENT.CONTRACT_CREATE, postResource
)
